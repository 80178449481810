import { SortingListingType } from '@/api/algolia';
import { AlgoliaClinicV2 } from '@/types/algolia-types';
import { SearchResponse } from '@algolia/client-search';
import { useCallback, useMemo, useState } from 'react';

type OrderStatus =
  | {
      loading: false;
      value: number | undefined;
    }
  | {
      loading: true;
      value: undefined;
    };

const defaultOrderValue = {
  loading: false,
  value: undefined,
};

export const useListingOrder = () => {
  const [closestDistance, setClosestDistance] = useState<OrderStatus>(defaultOrderValue);

  const [firstAvailability, setFirstAvailability] = useState<OrderStatus>(defaultOrderValue);

  const [cheapestInEuros, setCheapestInEuros] = useState<OrderStatus>(defaultOrderValue);

  const closestDistanceLabel = useMemo(() => {
    if (!closestDistance.value) return;

    const distance = closestDistance.value;
    if (distance < 1000) return `${Math.floor(distance).toFixed(0)} m da te`;
    if (distance < 10000) return `${Math.floor(distance / 1000).toFixed(0)},${(Math.floor(distance % 1000) / 100).toFixed(0)} km da te`;

    return `${distance.toFixed(0)} km da te`;
  }, [closestDistance.value]);

  const cheapestInEurosLabel = useMemo(() => {
    if (cheapestInEuros.value === undefined) return;
    return `${cheapestInEuros.value / 100}€`;
  }, [cheapestInEuros]);

  const firstAvailabilityLabel = useMemo(() => {
    if (!firstAvailability.value) return;

    const date = new Date(firstAvailability.value);
    const now = new Date();

    const diffTime = date.setHours(0, 0, 0, 0) - now.setHours(0, 0, 0, 0);
    const daysFromToday = Math.round(diffTime / (1000 * 60 * 60 * 24));
    if (daysFromToday === 0) return 'Oggi';
    if (daysFromToday === 1) return 'Domani';
    return date.toLocaleDateString('it-IT', {
      day: 'numeric',
      month: 'short',
    });
  }, [firstAvailability.value]);

  const extractDistance = useCallback((response: SearchResponse<AlgoliaClinicV2>) => {
    const distance = response?.hits?.at(0)?._rankingInfo?.geoDistance;
    if (!distance) return;
    if (distance / 100 < 100) return distance;
    return distance / 100;
  }, []);
  const extractPrice = useCallback(
    (response: SearchResponse<AlgoliaClinicV2>) => response?.facets_stats?.['specializations.price']?.min,
    [],
  );
  const extractFirstAvailability = useCallback(
    (response: SearchResponse<AlgoliaClinicV2>) => response?.facets_stats?.['firstAvailableVisitTimestamp']?.min,
    [],
  );

  const setOrderLoading = useCallback((key: SortingListingType) => {
    if (key === SortingListingType.distanceAsc) {
      setClosestDistance(prev => (prev.value === undefined ? { loading: true, value: undefined } : prev));
    } else if (key === SortingListingType.priceAsc) {
      setCheapestInEuros(prev => (prev.value === undefined ? { loading: true, value: undefined } : prev));
    } else if (key === SortingListingType.firstAvailabilityAsc) {
      setFirstAvailability(prev => (prev.value === undefined ? { loading: true, value: undefined } : prev));
    }
  }, []);

  const setOrderValue = useCallback(
    (key: SortingListingType, payload: SearchResponse<AlgoliaClinicV2>) => {
      if (key === SortingListingType.distanceAsc) setClosestDistance({ loading: false, value: extractDistance(payload) });
      if (key === SortingListingType.priceAsc) setCheapestInEuros({ loading: false, value: extractPrice(payload) });
      if (key === SortingListingType.firstAvailabilityAsc)
        setFirstAvailability({ loading: false, value: extractFirstAvailability(payload) });
    },
    [extractDistance, extractPrice, extractFirstAvailability],
  );

  const resetOrderValues = useCallback(() => {
    setClosestDistance(defaultOrderValue);
    setFirstAvailability(defaultOrderValue);
    setCheapestInEuros(defaultOrderValue);
  }, []);

  return {
    orderLabels: {
      closestDistanceLabel,
      cheapestInEurosLabel,
      firstAvailabilityLabel,
    },
    orderValuesLoading: {
      closestDistance: closestDistance.loading,
      cheapestInEuros: cheapestInEuros.loading,
      firstAvailability: firstAvailability.loading,
    },
    setOrderLoading,
    setOrderValue,
    resetOrderValues,
  };
};
